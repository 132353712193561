import React from "react";
import Navbar from "../components/App/Navbar";
import Layout from "../components/App/Layout";
import OurSolutions from "../components/RutherfordLabs/OurSolutions";
import OurMission from "../components/RutherfordLabs/OurMission";
import Funfacts from "../components/RutherfordLabs/Funfacts";
import HowItWork from "../components/RutherfordLabs/HowItWork";


const Home = () => {
  return (
    <Layout>
      <Navbar />
      <OurMission />
      {/* <MainBanner /> */}
      <OurSolutions />
      {/* <AboutArea /> */}  
      <Funfacts />
      {/* <UpcomingBootcamps /> */}
      <HowItWork />
      {/* <RecentProjects /> */}
      {/* <TeamMembers /> */}
      {/* <Testimonials /> */}
      {/* <Partner /> */}
      {/* <BlogPost /> */}
      {/* <StartProject /> */}
      {/* <Footer /> */}
    </Layout>
  );
};

export default Home;
