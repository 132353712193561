import React from 'react';

import StarIcon from '../../assets/images/star-icon.png';
import HowitsWork from '../../assets/images/how-its-work.png';

const HowItWork = () => {
    return (
        <section className="how-its-work-area ptb-100">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="how-its-work-content">
                            <span className="sub-title">
                                <img src={StarIcon} alt="icon" /> 
                                How We Get Things Done
                            </span>
                            <h2>A Human-Centered, Iterative Design Process</h2>
                            <p>
                                At the heart of our work is a human-centered design approach that blends creativity with cutting-edge data science. Inspired by the world-renowned processes of IDEO and Frog, we bring together empathy, experimentation, and innovation to create solutions that are as intuitive as they are impactful. Our iterative process allows us to continuously refine and enhance, ensuring each solution meets real-world needs.
                            </p>
                            <div className="inner-box">
                                <div className="single-item">
                                    <div className="count-box">
                                        1
                                    </div>
                                    <h3>Empathize</h3>
                                    <p>We begin by deeply understanding the needs, challenges, and aspirations of users. Through research and immersive exploration, we gather insights that guide every design decision.</p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        2
                                    </div>
                                    <h3>Define</h3>
                                    <p>Our team synthesizes research findings into clear, actionable problem statements, framing challenges in a way that inspires creative and effective solutions.</p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        3
                                    </div>
                                    <h3>Ideate</h3>
                                    <p>With a focus on creativity and collaboration, we explore a wide range of ideas. Our design and data science experts work together to conceptualize innovative solutions.</p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        4
                                    </div>
                                    <h3>Prototype</h3>
                                    <p>We bring ideas to life through rapid prototyping, allowing us to test assumptions, gather feedback, and make informed improvements quickly.</p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        5
                                    </div>
                                    <h3>Test & Iterate</h3>
                                    <p>Using real-world feedback, we refine and enhance each solution. This iterative testing process ensures that the final product is user-centered, effective, and ready to make an impact.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="how-its-work-image">
                            <img src={HowitsWork} alt="Our Process Diagram" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default HowItWork;
