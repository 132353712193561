import React from "react";

import FunIcon1 from "../../assets/images/funfacts/fun-icon1.png";
import FunIcon2 from "../../assets/images/funfacts/fun-icon2.png";
import FunIcon3 from "../../assets/images/funfacts/fun-icon3.png";
import FunIcon4 from "../../assets/images/funfacts/fun-icon4.png";

const Funfacts = () => {
  return (
    <div className="funfacts-area pb-70">
      <div className="container">
        <div className="section-title">
          <h2>Our Track Record Speaks for Itself</h2>
          <p>With decades of combined experience and a commitment to excellence, we bring proven expertise to every project. Here’s a quick look at what sets us apart.</p>
        </div>
        
        <div className="row">
          <div className="col-lg-3 col-sm-6 col-6 col-md-3">
            <div className="single-funfacts-box">
              <div className="icon">
                <img src={FunIcon1} alt="funfacts" />
              </div>
              <h3>50 Years</h3>
              <p>Combined experience in tech</p>
            </div>
          </div>

          <div className="col-lg-3 col-sm-6 col-6 col-md-3">
            <div className="single-funfacts-box">
              <div className="icon">
                <img src={FunIcon2} alt="funfacts" />
              </div>
              <h3>6+ Years</h3>
              <p>In AI and machine learning innovation</p>
            </div>
          </div>

          <div className="col-lg-3 col-sm-6 col-6 col-md-3">
            <div className="single-funfacts-box">
              <div className="icon">
                <img src={FunIcon3} alt="funfacts" />
              </div>
              <h3>100%</h3>
              <p>Client satisfaction rate</p>
            </div>
          </div>

          <div className="col-lg-3 col-sm-6 col-6 col-md-3">
            <div className="single-funfacts-box">
              <div className="icon">
                <img src={FunIcon4} alt="funfacts" />
              </div>
              <h3>10+ years</h3>
              <p>Healthcare technology consulting</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Funfacts;
