import React from 'react';
import { Link } from 'gatsby';

import StarIcon from '../../assets/images/star-icon.png';
import OurMissionShape from '../../assets/images/our-mission/our-mission-shape2.png';
import OurMissionImg from '../../assets/images/our-mission/our-mission1.png';
import OurMissionShape1 from '../../assets/images/our-mission/our-mission-shape1.png';

const OurMission = () => {
    return (
        <section className="our-mission-area ptb-100">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="our-mission-content">
                            <div className="content">
                                <span
                                    className="sub-title"
                                    style={{ display: 'block', paddingTop: '10px' }}
                                >
                                    <img src={StarIcon} alt="image" /> 
                                    Our Vision & Expertise
                                </span>
                                <h2>Innovating with AI and Cloud Solutions</h2>
                                <p>
                                    We are the creators of VocalBrain, a pioneering platform that brings together AI and machine learning to redefine vocal training and analysis. Leveraging our deep experience with TensorFlow, GCP, Firebase, React, and Flutter, we are not only building cutting-edge solutions but also sharing our expertise with clients as consultants.
                                </p>
                                <p>
                                    From real-time data processing to predictive analytics, our team has the expertise to turn complex technology into practical solutions that drive business results. By collaborating with Rutherford Labs, you tap into a wealth of experience and a commitment to excellence that has defined our journey.
                                </p>
                                
                                <ul className="our-mission-list">
                                    <li>
                                        <i className="flaticon-tick"></i>
                                        Advanced AI & ML Solutions
                                        <img src={OurMissionShape} alt="Our Mission Shape" />
                                    </li>
                                    <li>
                                        <i className="flaticon-tick"></i>
                                        Proven Results and Impact
                                        <img src={OurMissionShape} alt="Our Mission Shape" />
                                    </li>
                                    <li>
                                        <i className="flaticon-tick"></i>
                                        Strategic Guidance
                                        <img src={OurMissionShape} alt="Our Mission Shape" />
                                    </li>
                                    <li>
                                        <i className="flaticon-tick"></i>
                                        Fractional CTO & UX Expertise
                                        <img src={OurMissionShape} alt="Our Mission Shape" />
                                    </li>
                                </ul>
                                <p>
                                    <Link to="https://vocalbrain.com" className="btn btn-primary">
                                        Discover VocalBrain
                                    </Link>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="our-mission-image">
                            <img src={OurMissionImg} alt="image" />
                            <div className="shape">
                                <img src={OurMissionShape1} alt="image" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default OurMission;
